@font-face {
  font-family: font;
  src: url('Aileron-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: font;
  src: url('Aileron-Bold.otf');
  font-weight: 500;
}
@font-face {
  font-family: font;
  src: url('Aileron-Bold.otf');
  font-weight: 600;
}
* {
  font-family: 'Libre Franklin', sans-serif;
}
.font {
  font-family: font;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
  border: 2px solid #00b951;
  border-radius: 5px;
  transition: 150ms ease-in-out;
  background-color: #fff;
}
select {
  padding: 17px 30px!important;
  background-color: #fff!important;
  -webkit-appearance: initial;
}
input:hover, textarea:hover {
  transform: translate(0, -4px);
}
input::placeholder, textarea::placeholder {
  color: gray;
}
.sc::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.sc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(98, 98, 98/1);
}
.sc::-webkit-scrollbar-track {
  background-color: rgb(56, 56, 56/1);
  border-radius: 10px;
}
  
.absW {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}


.chevron-down {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.szh-accordion {
  &__item {
    border-bottom: 1px solid rgba(255,255,255,0.2);

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 2rem 0;
      font-weight: 400;
      text-align: left;
      color: #fff;
      font-family: font;
      font-size: 23px;
      background-color: transparent;
      border: none;
    }

    &-content {
      transition: height 0.2s ease-in-out;
    }

    &-panel {
      padding: 0 0 2rem 0;
      color: rgba(255,255,255,0.8);
    }

    &--expanded {
      .szh-accordion__item-btn {
        background-color: transparent;
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  input {
    padding: 12px 30px!important;
  }
}

.wht {
  filter: brightness(0) invert(1);
}
